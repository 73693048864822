import moment from "moment"
import React, { useEffect, useState } from "react"
import {
	Card,
	Col,
	Container,
	Modal,
	Row,
	Tab,
	Tabs,

} from "react-bootstrap"

import Settings from "../../common/Settings";
import './visit.css'
import ImageGallery from "react-image-gallery";
import AssignCustomer from "../Customers/AssignCustomer";

import AssignUser from "../Users/AssignUser";
import AddEditVisit from "./AddEditVisit";
import { parsedUser } from "../../common/GetCurrentUser";
import API from "../../common/API";
import { useTranslation } from "react-i18next";
import AddEditPlace from "./AddEditPlace";
// import PhotoCategoryAssign from "../RepslyPhoto/PhotoCategoryAssign";

const VisitDetails = props => {
	const [model, setModel] = useState(props.model)
	const [isLoading, setIsLoading] = useState(false);
	const { t } = useTranslation("global");
	const [images, setImages] = useState([])
	const [showCategory, setShowCategory] = useState(false);
	const [showAddEdit, setShowAddEdit] = useState(false);
	const [showAssignCustomer, setShowAssignCustomer] = useState(false);
	const toggleAssignCustomer = () => {
		setShowAssignCustomer(!showAssignCustomer);
		if (showAssignCustomer) {
			requestDetails()
		}
	}

	const toggleAddEdit = () => {
		setShowAddEdit(!showAddEdit);
		if (showAddEdit) {
			requestDetails()
		}
	}

	const [theMap, setTheMap] = useState(null);
	const [theMapsReference, setTheMapsReference] = useState(null);

	const handleApiLoaded = (map, mapsReference) => {
		setTheMap(map);
		setTheMapsReference(mapsReference);
	};
	const [position, setPosition] = useState({ lat: 18.4718609, lng: -69.8923187 });

	const requestDetails = async () => {
		let query = "id=" + model.id
		// query += "&OrganizationId=" + user.OrganizationId;
		let requestAPI = await API.getAction("place/Details", query)
		if (requestAPI.data.status === "ok") {
			setModel(requestAPI.data.response)
		}
	}
	// const [showLightbox, setShowLightbox] = useState(false);
	// const toggleLightbox = () => {
	// 	setShowLightbox(!showLightbox);
	// }

	useEffect(() => {
		if (model) {
			setPosition({ lat: model.latitude * 1, lng: model.longitude * 1 })
			let imgs = [];
			if (model.image) {
				imgs.push({
					original: `${Settings.BasePath}files/${model.image}`,
					thumbnail: `${Settings.BasePath}files/${model.image}`,
					originalClass: "originalSize"
				})
			}
			if (model.image2) {
				imgs.push({
					original: `${Settings.BasePath}files/${model.image2}`,
					thumbnail: `${Settings.BasePath}files/${model.image2}`,
					originalClass: "originalSize"
				})
			}

			setImages(imgs);
			requestVisits();
		}
	}, [model])

	const toggleShowCategory = (selected) => {
		setShowCategory(!showCategory);

	}

	const [showAssignUsers, setShowAssignUsers] = useState(false);
	const toggleAssignUsers = () => {
		setShowAssignUsers(!showAssignUsers)
	}

	const [showAddEditVisit, setShowAddEditVisit] = useState(false);
	const toggleAddEditVisit = () => {
		setShowAddEditVisit(!showAddEditVisit)
	}

	const [visits, setVisits] = useState([])
	const user = parsedUser();
	const requestVisits = async () => {

		let query = "placeId=" + model.id;
		// query += "&OrganizationId=" + user.OrganizationId;
		let requestAPI = await API.getAction("place/VisitList", query);
		if (requestAPI.data.status === "ok") {
			setVisits(requestAPI.data.response);
		}
	};


	const savePlace = async () => {
		try {
			setIsLoading(true)
			let request = await API.postAction("place/AddEdit", model)

			console.log(request)
			// if (request.data.status === "ok") {
			//   requestDetails()
			// }
		} catch (ex) {
			console.error(ex)
		} finally {
			setIsLoading(false)
		}
	}


	useEffect(() => {
		requestDetails()
	}, [])

	if (!model) {
		return (<></>)
	}


	return (
		<>
			{/* <FsLightbox
				toggler={toggleLightbox}
				sources={images.map((img) => img.original)}
			/> */}
			{/* {showCategory ? <PhotoCategoryAssign show={showCategory} toggle={toggleShowCategory} PhotoId={selectedPhoto} /> : null} */}
			{showAddEditVisit && <AddEditVisit show={showAddEditVisit} toggle={toggleAddEditVisit} place={model} />}
			{showAssignCustomer ? <AssignCustomer show={showAssignCustomer} toggle={toggleAssignCustomer} place={model} /> : null}
			{showAssignUsers ? <AssignUser show={showAssignUsers} toggle={toggleAssignUsers} place={model} /> : null}
			{showAddEdit ? <AddEditPlace show={showAddEdit} toggle={toggleAddEdit} model={model} /> : null}
			<Modal
				show={props.show}
				onHide={props.toggle}
				size="xl"
				className="modalRight"
			>
				<Modal.Header closeButton>
					<Row className="mx-0 w-100">
						<Col>
							<h3 className="d-inline">{model.name}</h3>
							<button className="btn btn-primary btn-sm ms-2" onClick={() => {
								toggleAddEdit()
							}}>
								<i className="fas fa-edit"></i>
							</button>

						</Col>
					</Row>
				</Modal.Header>
				<Modal.Body className="p-0" style={{ overflow: 'auto' }}>
					<Container>
						<Row>
							<Col xs={12} md={12} xl={4} className="p-0">
								<Card className="shadow-sm m-2">
									<Card.Body className="p-1">
										<table className="table table-striped">
											<tbody>
												<tr>
													<td>Representante</td>
													<td>
														{model.assignedToObj && <span>{model.assignedToObj.firstName} {model.assignedToObj.lastName}</span>}
														<button className="btn btn-primary btn-sm ms-2 d-none"
															onClick={() => toggleAssignUsers()}
														>
															Asignar
														</button>
													</td>

												</tr>
												<tr>
													<td>Cliente</td>
													<td>
														{model.customer && <b className="clickable text-truncate "
															onClick={() => toggleAssignCustomer()}
														>{model.customer.name}</b>}
														{!model.customer && <button className="btn btn-primary btn-sm ms-2 " type="button"
															onClick={() => toggleAssignCustomer()}>
															Asignar
														</button>}
													</td>

												</tr>

												{/* <tr>
													<td>Proyecto</td>
													<td>
														{model.name}
													</td>
												</tr> */}
												{model.category ?
													<tr>
														<td>Categoría</td>
														<td>
															{model.category.name}
														</td>
													</tr>
													: null}
												{model.stage ?
													<tr>
														<td>Etapa</td>
														<td>
															{model.stage.name}
														</td>
													</tr>
													: null}
												<tr>
													<td>Fecha</td>
													<td>
														<small className="text-muted">{moment(model.created).fromNow()}</small></td>
												</tr>
												<tr>

													<td className="text-wrap" colSpan={2}>
														{`${model.address ?? ""}, ${model.sector ?? ""}, ${model.city ?? ""}, ${model.region ?? ""}, ${model.country}`}
														<br />
														<button
															className="btn btn-success btn-sm"
															type="button"
															onClick={() => {
																let fullAddress = "";
																fullAddress = `${model.latitude},${model.longitude}`
																//`${model.item.streetAddress ?? ""}, ${model.item.city ?? ""}, ${model.item.state ?? ""}, ${model.item.country}`;
																window.open(
																	`https://www.google.com/maps/search/?api=1&query=${fullAddress
																	}`,
																	"MapWindow",
																	"width=1280,height=840"
																);
															}}
														>GO
														</button>
													</td>
												</tr>
											</tbody>
										</table>
									</Card.Body>
									<Card.Footer>
										<button className="btn btn-block btn-secondary mb-4" onClick={() => toggleAddEditVisit()}>
											<i className="fa fa-plus me-2"></i> Agregar Visita
										</button>
									</Card.Footer>
								</Card>


								<Card className="shadow-sm m-2">
									<Card.Header className="p-1 px-2">
										<Card.Title> {t("Technical details")}</Card.Title>
									</Card.Header>
									<Card.Body className=" bg-light">
										<Row>
											<Col xs={12}>
												<label>{t("Quantity of Floors")}</label>
												<br />
												<input
													type="number"
													className="form-control"
													value={model.quantityOfFloor}
													onChange={e => {
														setModel({
															...model,
															quantityOfFloor: e.target.value,
														})
													}}
												></input>
											</Col>

											<Col xs={12} className="mt-2">
												<label>{t("Departments per Floor")}</label>
												<br />
												<input
													type="number"
													className="form-control"
													value={model.departmentPerFloor}
													onChange={e => {
														setModel({
															...model,
															departmentPerFloor: e.target.value,
														})
													}}
												></input>
											</Col>
											<Col xs={12} className="mt-2">
												<label>{t("Rooms per Department")}</label>
												<br />
												<input
													type="number"
													className="form-control"
													value={model.roomsPerApartment}
													onChange={e => {
														setModel({
															...model,
															roomsPerApartment: e.target.value,
														})
													}}
												></input>
											</Col>
											<Col xs={12} className="mt-2">
												<label>{t("Estimated Dimensions")}</label>
												<br />
												<input
													type="number"
													className="form-control"
													value={model.estimatedDimensions}
													onChange={e => {
														setModel({
															...model,
															estimatedDimensions: e.target.value,
														})
													}}
												></input>
											</Col>
											<Col xs={12} md={6} className="mt-2">
												<label>{t("Credit Code")}</label>
												<br />
												<input
													type="text"
													className="form-control"
													value={model.creditCode}
													onChange={e => {
														setModel({
															...model,
															creditCode: e.target.value,
														})
													}}
												></input>
											</Col>
											<Col xs={12} md={6} className="mt-2">
												<label>{t("Work Code")}</label>
												<br />
												<input
													type="text"
													className="form-control"
													value={model.workCode}
													onChange={e => {
														setModel({
															...model,
															workCode: e.target.value,
														})
													}}
												></input>
											</Col>
										</Row>
									</Card.Body>
									<Card.Footer>
										<button
											disabled={isLoading}
											className="btn btn-block btn-primary"
											onClick={() => savePlace()}
										>
											<i className="fa fa-save me-2"></i>
											{t("Save")}
										</button>
									</Card.Footer>
								</Card>


							</Col>

							<Col xs={12} md={12} lg={8}>
								<Tabs
									defaultActiveKey="image"
									id="uncontrolled-tab-example"
									className="mb-3"
								>
									<Tab eventKey="image" title="Imagenes">
										<ImageGallery items={images} originalWidth="200" />
									</Tab>

									{/* <Tab eventKey="notes" title="Notes" disabled>
										Tab content for Contact
									</Tab> */}
									<Tab eventKey="visits" title="Log de Visitas" >

										{visits && visits.map((item, index) => {
											return (<Card key={index} className="mb-2 shadow-sm">

												<div className="row g-0">
													<div className="col-md-4">
														<img src={`${Settings.BasePath}files/${item.image}`} className="img-fluid rounded-start" alt="..." />
													</div>
													<div className="col-md-8">
														<div className="card-body">
															{/* <h5 className="card-title">Card title</h5> */}

															<p className="card-text">
																<small className="text-muted">{moment(item.created).fromNow()}</small><br />
																{item.note}</p>
															<button
																className="btn btn-success btn-sm"
																type="button"
																onClick={() => {
																	let fullAddress = "";
																	fullAddress = `${item.latitude},${item.longitude}`
																	//`${model.item.streetAddress ?? ""}, ${model.item.city ?? ""}, ${model.item.state ?? ""}, ${model.item.country}`;
																	window.open(
																		`https://www.google.com/maps/search/?api=1&query=${fullAddress
																		}`,
																		"MapWindow",
																		"width=1280,height=840"
																	);
																}}
															>GO
															</button>
														</div>
													</div>
												</div>

											</Card>)
										})}
									</Tab>
								</Tabs>


							</Col>


						</Row>
					</Container>
				</Modal.Body>
				<Modal.Footer className="p-1"></Modal.Footer>
			</Modal>
		</>
	)
}

export default VisitDetails
