import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { parsedUser } from "../../common/GetCurrentUser";
import API from "../../common/API";
import { useTranslation } from "react-i18next";
import AddEditCustomer from "./AddEditCustomer";

const AssignCustomer = (props) => {
  const user = parsedUser();
  const { t } = useTranslation("global");
  const [records, setRecords] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const toggleAdd = async () => {
    setShowAdd(!showAdd);
    if (showAdd) {
      await bindDataRemote();
    }
  };

  const assignCustomer = async (obj) => {
    const query = "customerId=" + obj.id + "&placeId=" + props.place.id;
    const request = await API.getAction("customer/assignPlace", query);
    props.toggle();
  };
  const bindDataRemote = async () => {
    let Query = "OrganizationId=" + user.OrganizationId;

    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction("customer/list", Query);

    if (requestAPI.data.status === "ok") {
      var result = requestAPI.data.response;
      setRecords(result);
    }
  };

  useEffect(() => {
    bindDataRemote();
  }, []);

  return (
    <>
      {showAdd && <AddEditCustomer show={showAdd} toggle={toggleAdd} />}
      <Modal show={props.show} onHide={props.toggle} className="modalRight">
        <Modal.Header closeButton>
          <Modal.Title>{t("Assign Customer")}</Modal.Title>
          <button
            className="btn btn-sm btn-primary ms-2"
            onClick={() => toggleAdd()}
          >
            <i className="fas fa-plus"></i>
          </button>
        </Modal.Header>
        <Modal.Body className="p-0" style={{ overflow: "auto" }}>
          <div className="table-responsive">
            <table className="table table-striped">
              <tbody>
                {records &&
                  records.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.name}</td>
                        <td className="text-end">
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={() => {
                              console.log("assign");
                              assignCustomer(item);
                            }}
                          >
                            {t("Assign")}
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AssignCustomer;
