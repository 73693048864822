import React, { useEffect, useState } from "react";
import { Card, Col, Modal, Row } from "react-bootstrap";
import CustomerDTO from "./CustomerDTO";
import CRUDField from "../core/genericCRUD/CRUDField";
import FieldSelector from "../core/FieldSelector";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";
import yupTypeValidator from "../../common/YupTypeValidator";
import API from "../../common/API";

import { toast } from "react-toastify";
import { set } from "lodash";
import { parsedUser } from "../../common/GetCurrentUser";

const AddEditCustomer = (props) => {
  type YupSchema =
    | yup.StringSchema
    | yup.BooleanSchema
    | yup.NumberSchema
    | yup.DateSchema;
  // PROPERTIES
  const user = parsedUser();
  const [record, setRecord] = useState<any>(null);
  const [recordRelatives, setRecordRelatives] = useState<any>([]);
  const fields: CRUDField[] = CustomerDTO;
  const { t } = useTranslation("global");
  const [formikInitialObject, setFormikInitialObject] = useState<any>({});
  const [validationsSchema, setValidationSchema] =
    useState<yup.ObjectSchema<any>>();
  const [autorization, setAutorization] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: formikInitialObject,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationsSchema,
    onSubmit: () => handleSubmit(),
  });
  // METHODS

  const fillFormikObject = (record: any) => {
    fields.forEach((item: CRUDField) => {
      if (item && item.name.length > 0) {
        //console.log("field name", item.name, "RecordValue ", record[item.name]);
        if (record && record[item.name]) {
          formik.setFieldValue(item.name, record[item.name]);
        }
      }
    });
  };

  const prepareFormikObject = () => {
    const initialObject: { [key: string]: string } = {};
    const initialObjectValidation: { [key: string]: YupSchema } = {};

    if (fields) {
      fields.forEach((item: CRUDField) => {
        // if (item.FieldName === "Id" || item.FieldName === "File") return;

        initialObject[item.name] = item.value;

        if (item.required) {
          const schema = yupTypeValidator(item);
          if (schema) {
            initialObjectValidation[item.name] = schema;
          }
        }
      });
    }

    // formik.initialValues = initialObject;
    setFormikInitialObject(initialObject);
    setValidationSchema(yup.object().shape(initialObjectValidation));
  };

  const updateField = (fieldName: string, fieldValue: any) => {
    console.log(fieldName, fieldValue);
    formik.setFieldValue(fieldName, fieldValue);
  };

  const handleSubmit = async () => {
    try {
      console.log("values to submit", formik.values);

      let query: any = {};
      fields.forEach((item: CRUDField) => {
        if (item && item.htmlType !== "separator" && item.name.length > 0) {
          query[item.name] = formik.values[item.name] ?? null;
        }
      });

      // add media

      setIsLoading(true);
      let request = (await API.postAction(`customer/AddEdit`, query)) as any;
      if (request.data.status === "ok") {
        toast.success(t("record saved"));
        props.toggle();
      }
    } catch (ex) {
      console.error(ex);
    } finally {
      setIsLoading(false);
    }
  };

  const bindDataRemote = async () => {
    try {
      updateField("organizationId", user.OrganizationId);
      if (record === null) return;
      console.log("bind data remote", record);
      setIsLoading(true);
      let request = await API.getAction("customer/Details?id=" + record.id);
      if (request.data.status === "ok") {
        setRecordRelatives(request.data.response.relatives);
        fillFormikObject(request.data.response);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  // EVENTS
  useEffect(() => {
    prepareFormikObject();
  }, []);

  useEffect(() => {
    setRecord(props.record);
  }, [props.record]);

  useEffect(() => {
    console.log("load remote content");
    bindDataRemote();
  }, [record]);

  useEffect(() => {
    console.error("errors", formik.errors);
  }, [formik.errors]);
  return (
    <Modal
      show={true}
      onHide={props.toggle}
      size={props.modalSize ? props.modalSize : "lg"}
      className={props.cssClass + " modalRight"}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("Customer")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex gap-2 flex-col px-4">
          <Row>
            {fields &&
              fields
                .filter((x) => x.htmlType !== "hidden")
                .map((item, index) => {
                  return (
                    <FieldSelector
                      key={index}
                      model={{
                        ...item,
                        readOnly: props.readOnly ?? false,
                      }}
                      updateField={updateField}
                      obj={formik.values}
                      value={
                        formik.values[item.name as keyof typeof formik.values]
                      }
                      errors={formik.errors}
                    />
                  );
                })}
          </Row>
        </div>
      </Modal.Body>

      <Modal.Footer className="p-1 px-2">
        <Row className="w-100">
          <Col className="text-end">
            {!props.readOnly && (
              <div className="modal-action m-0 p-2 ">
                {isLoading && (
                  <div className="me-4 d-inline-block">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}

                <button
                  className="btn btn-secondary me-2"
                  disabled={isLoading}
                  onClick={() => props.toggle()}
                  type="button"
                >
                  {t("Cancel")}
                </button>

                <button
                  className="btn btn-primary text-white"
                  disabled={isLoading}
                  onClick={() => formik.submitForm()}
                  type="button"
                >
                  {t("Save")}
                </button>
              </div>
            )}
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default AddEditCustomer;
