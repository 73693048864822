import React, { useEffect, useState } from "react";
//import { Card, Button } from "react-bootstrap"

//import Profile1 from "../../assets/img/team/profile-picture-1.jpg"
//import ProfileCover from "../../assets/img/profile-cover.jpg"
import API from "../../common/API";
import { parsedUser } from "../../common/GetCurrentUser";
import { userAvatar } from "../../common/helpers";
import AddEditOrganization from "./AddEditOrganization";

//Translation
import { useTranslation } from "react-i18next";
import { Routes } from "../../routes";
import { useHistory } from "react-router-dom";

const OrganizationBox = (props) => {
  const [state, setState] = useState({
    Organization: null,
    isLoading: false,
    showEdit: false,
  });

  const theUser = parsedUser();
  const history = useHistory();
  const { t } = useTranslation("global");

  const request = async () => {
    setState({
      isLoading: !state.isLoading,
      ...state,
    });
    let requestAPI = await API.getAction(
      "Organization/Details?Id=" + theUser.OrganizationId
    );

    if (requestAPI.data.status === "ok") {
      setState({
        isLoading: !state.isLoading,
        Organization: requestAPI.data.result,
      });
    }
  };
  const toggleAddEdit = (reload) => {
    setState({
      ...state,
      showEdit: !state.showEdit,
    });

    if (reload) {
      request();
    }
  };
  useEffect(() => {
    request();
  }, []);

  return (
    <>
      {state.showEdit ? (
        <AddEditOrganization
          show={state.showEdit}
          toggle={toggleAddEdit}
          model={state.Organization}
        />
      ) : null}
      <div className="row mb-4">
        {/* <div className="col-auto">
          <img
            src={state.Organization && userAvatar(state.Organization.name, 160)}
            className="user-avatar  rounded-circle img-fluid"
          />
        </div> */}
        <div className="col-auto">
          <h3
            className="p-0 m-0 clickable"
            onClick={() => {
              history.push(Routes.SelectOrganization.path);
            }}
          >
            {state.Organization && state.Organization.name}
          </h3>

          <small>{state.Organization && state.Organization.email}</small>
        </div>
        <div className="col-auto ml-auto">
          {props.readOnly ? null : (
            <button
              className="btn btn-primary btn-sm "
              onClick={() => toggleAddEdit()}
            >
              {t("edit")}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default OrganizationBox;
