
import Profile3 from "../assets/img/team/profile-picture-3.jpg";
export default Helper => {
    console.log("helpers");
};

export const PayrollTypes = { hourly: 4, weekly: 1, biWeekly: 2, monthly: 3 }
export const TransactionType = { percentage: 1, factor: 2, amount: 3 }

const colors = ["3f67fa"];//["3f67fa", "fbcb6d", "0e1d2c", "bdbcd9"]
Array.prototype.getRandom = function (cut) {
    var i = Math.floor(Math.random() * this.length);
    if (cut && i in this) {
        return this.splice(i, 1)[0];
    }
    return this[i];
}
export const userAvatar = (userName, size) => {
    if (userName === null) {
        return Profile3;
    }
    let spli = userName.split(' ');
    let initials = "";
    spli.forEach(element => {
        if (initials.length < 2) {
            initials += element.substring(0, 1).toUpperCase();
        }
    });


    let colorToUse = colors.getRandom();
    size = size ? size : 75
    let path = "https://via.placeholder.com/" + size + "/" + colorToUse + "/ffffff/?text=" + initials;
    return path;
}

export const onlyHTMLTags = text => {
    var hold = document.createElement("div");
    hold.innerHTML = text.trim();
    return hold.innerText.length === 0;
}


export const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
}



export const sortArray = (records, field, type) => {
    return records.sort((a, b) => {
        let nameA = null, nameB = null;
        if (type === "number") {
            nameA = a[field];
            nameB = b[field];
        } else {
            nameA = a[field].toUpperCase(); // ignore upper and lowercase
            nameB = b[field].toUpperCase(); // ignore upper and lowercase
        }

        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        // names must be equal
        return 0;
    });
}



//n= number
//c= comma separator
//t = houndry separator
//
export const FormatMoney = function (n, c, decimal, t) {
    let s, i, j
    c = isNaN(c = Math.abs(c)) ? 2 : c,
        decimal = decimal === undefined ? "." : decimal,
        t = t === undefined ? "," : t,
        s = n < 0 ? "-" : "",
        i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
        j = (j = i.length) > 3 ? j % 3 : 0;
    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? decimal + Math.abs(n - i).toFixed(c).slice(2) : "");
};


export const NumberFormat = function (item) {
    return new Intl.NumberFormat().format(isNaN(item * 1) ? 0 : item * 1)
}


export const makeId = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

/**
 * Format bytes as human-readable text.
 * 
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use 
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 * 
 * @return Formatted string.
 * https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
 */
export const prettyFileSize = (bytes, si = false, dp = 1) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
}